import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./TotalnurseTable.css";
import Managenursemodal from "./Managenursemodal";
import Managenurseform from "./Managenurseform";
import axios from "axios";
import { apiurl } from "../../App";
import DeleteNurse from '../../helpers/ModalComp/deleteModal';
import { message } from 'antd';
import Nurse_form from "./Nurse_form";
import ShowNotification from "../ShowNotification/ShowNotification";

class DashboardTable extends React.Component {
  state = {
    editopen: false,
    openview: false,
    deleteopen: false,
    tableData: [],
    props_loading: true,
    history_data: [],
    history_data_store: [],
    visible: false,
    successmsg: "",
    issuccess: false,
  };

  componentWillReceiveProps() {
    this.setState({ totalData: this.props.totalData });
  }

  createData = (parameter) => {
    var keys = Object.keys(parameter);
    var values = Object.values(parameter);
    var returnobj = {};
    for (var i = 0; i < keys.length; i++) {
      returnobj[keys[i]] = values[i];
    }
    return returnobj;
  };


  modelopen = (data, id) => {
    const viewData = this.props.totalData.find(val => val.nurseId === id);
    if (data === "view") {
      this.setState({ workflow: true })
      this.setState({ viewData });
    } else if (data === "edit") {
      this.setState({ editopen: true })
      this.setState({
        editData: viewData,
        props_loading: false
      });
    } else if (data === "history") {
      this.props.getNurseHistory(id, viewData.name);
      this.setState({ historyopen: true });
    }
  }

  closemodal = () => {
    this.setState({ openview: false, editopen: false, deleteopen: false, historyopen: false, workflow: false });
  }

  HistoryTableData = (id) => {
    axios({
      method: 'post',
      url: apiurl + 'getNursePatientHistory',
      data: {
        nurseId: id,
      }
    }).then((response) => {
        var history_data = [];
        response.data.data.map((val) => {
          history_data.push(val);
        });
        this.setState({ history_data: history_data });
      }).catch( (error) => {
        console.log(error);
      })
  }

  deleteopen = (type, id) => {
    this.setState({ deleteopen: true, iddata: id });
  }

  deleterow = () => {
    this.setState({ props_loading: true });
    var self = this;
    axios({
      method: 'delete',
      url: apiurl + 'nurse/deleteNurseInfo',
      data: {
        "id": this.state.iddata,
      }
    }).then(function (response) {
        if (response.data.status === 1 ) {
          debugger;
          // message.success('Nurse Deleted Successfully');
          if(localStorage.nurse_language=='ar'){
            self.handleopenSuccess("!تم حذف الممرضة", true)
          }else{
            self.handleopenSuccess("Nurse deleted successfully!", true)
          }
        } else {
          // message.error('This Nurse is already Booked. Cannot Delete');
          if(localStorage.nurse_language=='ar'){
            self.handleopenSuccess("حجزت بالفعل. غير قادر على الحذف.", false)
          }else{
            self.handleopenSuccess("Already Booked. Not able to delete.", false)
          }
        }
        self.setState({ props_loading: false });
        self.props.getTableData();
      }).catch(function (error) {
        self.setState({ props_loading: false });
      });
  }

  
  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };

  render() {
    const { t } = this.props;
    const heading = ((localStorage.nurse_language=='ar') ?[
      { id: "", label: t("action") },
      { id: "", label: t("tbl_head_Active") },
      { id: "nationality", label: t("tbl_head_nationality") },
      { id: "experience", label: t("tbl_head_experience") },
      { id: "age", label: t("tbl_head_age") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "nurseName", label: t("tbl_head_nurse") },
      { id: "", label: t("tbl_head_s_no") },
    ]:[
      { id: "", label: t("tbl_head_s_no") },
      { id: "nurseName", label: t("tbl_head_nurse") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "age", label: t("tbl_head_age") },
      { id: "experience", label: t("tbl_head_experience") },
      { id: "nationality", label: t("tbl_head_nationality") },
      { id: "", label: t("tbl_head_Active") },
      { id: "", label: t("action") },
    ])
    return (
      <div className="manage_nurse_table_div">
        <Tablecomponent
          heading={heading}
          rowdata={this.props.tableData}
          props_loading={this.props.props_loading}
          deleteopen={this.deleteopen}
          modelopen={(e, id) => this.modelopen(e, id)}
          LocationOnIcon="close"
          t={t}
          i18n={this.props.i18n}
        />
        <Modalcomp
          visible={this.state.workflow}
          editData={this.state.editData}
          title={t("view_nurse")}
          clrchange="textclr"
          closemodal={(e) => this.closemodal(e)}
          xswidth={"md"}
          modelwidthClass={`${this.props.i18n.language=='ar'?"arabic_cls":""} `}
          t={t}
          i18n={this.props.i18n}
        >
          <Managenursemodal
            open={this.state.workflow}
            onClose={this.closemodal}
            viewData={this.state.viewData}
            nationality={this.props.nationality}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>
        <Modalcomp
          visible={this.state.historyopen}
          title={t("nurse_history")}
          closemodal={(e) => this.closemodal(e)}
          clrchange="textclr"
          xswidth={
            this.state.history_data[0] &&
            this.state.history_data[0].patientHistory.length > 2
              ? "lg"
              : "md"
          }
          modelwidthClass={`${this.props.i18n.language=='ar'?"arabic_cls":""} `}
          t={t}
          i18n={this.props.i18n}
        >
          <Managenurseform
            history_data_store={this.state.history_data}
            closemodal={this.closemodal}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>

        <Modalcomp
          visible={this.state.editopen}
          editData={this.state.editData}
          title={t("edit_nurse")}
          clrchange="textclr"
          closemodal={(e) => this.closemodal(e)}
          xswidth={"md"}
          modelwidthClass={`${this.props.i18n.language=='ar'?"arabic_cls":""} `}
          t={t}
          i18n={this.props.i18n}
        >
          <Nurse_form
            getTableData={() => this.props.getTableData()}
            closemodal={this.closemodal}
            editData={this.state.editData}
            editopenModal={this.state.editopen}
            nationality={this.props.nationality}
            handleopenSuccess={this.handleopenSuccess}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>
        <Modalcomp
          visible={this.state.deleteopen}
          title={t("delete_nurse")}
          closemodal={this.closemodal}
          xswidth={"xs"}
          clrchange="textclr"
          modelwidthClass={`${this.props.i18n.language=='ar'?"arabic_cls":""} manage_nurse_delete_popup`}
          t={t}
          i18n={this.props.i18n}
        >
          <DeleteNurse
            deleterow={this.deleterow}
            closemodal={this.closemodal}
            msg={t("nurse_delete_msg")}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
}

export default DashboardTable;
