import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import './Modalcomp.css'

export default class ActiveNurseStatus extends Component {
    render() {
        const { t } = this.props;
        console.log(this.props.active_status_nusre.is_active,9999)
        return (
            <div className="record_delete_container">
                <Grid container>
                    <Grid item xs={12} md={12} className="media_title_container">
                    {/* style={{marginBottom : "3rem !important"}} */}
                        <div className="mediadelete_container">
                            <p className="delete_para">{this.props.msg ? this.props.msg :t(this.state.selectedData?.is_active === 1 ? "in_active_nurse" : "active_nurse")}</p>                            
                        </div>
                        <div className="mediadelete_container"   style={{direction:this.props.i18n.language == "ar"? "rtl": ""}}
                        onClick={() => this.props.closemodal()}>
                            <Button className="detete_no">{t("no")}</Button>
                            <Button className="detete_yes"   style={{marginRight:this.props.i18n.language == "ar"? "10px": ""}} onClick={() => this.props.activenurse(this.props.active_status_nusre)}>{t('yes')}</Button>
                        </div>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
