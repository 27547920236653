// div correction code
import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import dateFormat from "dateformat";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Select } from "antd";
import moment from "moment";

function disableDates(date, mindate) {
  return date.getDate() == (mindate ? mindate.getDate() : 0);
}

export default class Labelbox extends Component {
  constructor(props) {
    super(props);
    console.log("valid date", props.value);
    this.state = {
      gender: "M",
      open: false,
      value: null,
      selectedtime: props.value ? props.value : new Date(),
      selecteddate: props.value ? props.value : new Date(),
      // maxlength:props.value.maxlength
    };
  }
  changeGender = (data) => {
    this.setState({ gender: data });
    this.props.changeGender && this.props.changeGender(data);
  };
  datepickerChange = (date) => {
    if (date == "Invalid Date") {
      this.props.invalidate && this.props.invalidate(date);
    } else {
      var today = new Date();
      // var date=moment(date).format('DD-MMM-YYYY');
      // var datefmt = dateFormat(date, "yyyy-MMM-dd");
      var datefmt = moment(date).format("DD-MMM-YYYY");
      this.props.changeData && this.props.changeData(datefmt);
    }
  };
  timepickerChange = (time, changeFormat) => {
    console.log("time", time);
    var timeformat = dateFormat(time, changeFormat ? changeFormat : "hh:MM:ss");
    console.log("timeformat", timeformat);
    this.setState({ selectedtime: time });
    this.props.changeData && this.props.changeData(timeformat);
  };

  componentWillReceiveProps(props) {
    if (props.type == "datepicker") {
      if (isNaN(new Date(props.value).getTime())) {
      } else {
        var datefmt = dateFormat(props.value && props.value, "yyyy-mm-dd");
        this.setState({ selecteddate: datefmt });
      }
    }
    if (props.gendervalue) {
      this.setState({ gender: props.gendervalue });
    }
  }
  onChange = (time) => {
    this.setState({ value: time });
    this.props.changeData && this.props.changeData(time);
  };
  handleSearch = (value) => {
    if (value) {
      fetch(value, (data) => this.setState({ data }));
    } else {
      this.setState({ data: [] });
    }
  };

  renderinput = (data) => {
    if (data.type == "text") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls`}
              value={this.props.value}
              maxLength={this.props.maxlength}
              disabled={this.props.disabled}
              type="text"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly ? this.props.readOnly : false}
            />
            {
              <div className="Errormsg">
                <div>{data.error && data.errmsg}</div>
              </div>
            }
          </div>
        </div>
      );
    } else if (data.type == "number") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <input
              className={`${data.error && "brdred"} brdrcls`}
              min="0"
              max={this.props.maxlength}
              value={this.props.value}
              disabled={this.props.disabled ? this.props.disabled : false}
              type="number"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              onKeyDown={(e) =>
                (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") &&
                e.preventDefault()
              }
              maxLength={this.props.maxlength}
              onInput={(e) => {
                if (e.target.value.length > this.props.maxlength) {
                  e.target.value = e.target.value.slice(
                    0,
                    this.props.maxlength
                  );
                }
              }}
            />
            {
              <div className="Errormsg">
                <div>{data.error && data.errmsg}</div>
              </div>
            }
          </div>
        </div>
      );
    } else if (data.type == "number1") {
      const { value, changeData } = this.props;
      let valueNumber = value;
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div  className={`${data.error && "brdred"}`}style={{display: "flex", alignItems: 'center', border: '1px solid #ccc', borderRadius:'2px !important'}}>
            <span style={{ marginRight: "8px", color: '#510f30'}}>+965</span>
            <input
              className={`brdrcls1`}
              min="0"
              max={this.props.maxlength}
              value={valueNumber}
              disabled={this.props.disabled ? this.props.disabled : false}
              type="number"
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              onKeyDown={(e) =>
                (e.key === "e" || e.key === "+" || e.key === "-") &&
                e.preventDefault()
              }
              maxLength={this.props.maxlength}
              onInput={(e) => {
                if (e.target.value.length > this.props.maxlength) {
                  e.target.value = e.target.value.slice(
                    0,
                    this.props.maxlength
                  );
                }
              }}
            />
          </div>
          {
            <div className="Errormsg">
              <div>{data.error && data.errmsg}</div>
            </div>
          }
        </div>
      );
    } else if (data.type == "textarea") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div style={{ width: "100%" }}>
            <textarea
              className={`${data.error && "brdred"} brdrcls`}
              rows="3"
              cols="50"
              value={this.props.value}
              onChange={(e) =>
                this.props.changeData && this.props.changeData(e.target.value)
              }
              readOnly={this.props.readOnly ? this.props.readOnly : false}
            ></textarea>
            {
              <div className="Errormsg">
                <div>{data.error && data.errmsg}</div>
              </div>
            }
          </div>
        </div>
      );
    } else if (data.type === "duty_radio") {
      return (
        <div
          className="dutyDiv"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label className="labeltxt">{data.labelname}</label>
          <FormControlLabel
            control={
              <Radio
                className="radiobtncolor"
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                onClick={() => this.changeGender("08:00")}
                checked={this.props.checked === "08:00"}
                fontSize="small"
                classKey="colorPrimary"
              />
            }
            label="12 Hours"
            style={{ marginLeft: "2rem" }}
          />
          <FormControlLabel
            value="female"
            control={
              <Radio
                className="radiobtncolor"
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                onClick={() => this.changeGender("12:00")}
                checked={this.props.checked === "12:00"}
                fontSize="small"
              />
            }
            label="24 Hours"
          />
        </div>
      );
    } else if (data.type === "radio") {
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div
            style={{
              marginTop: this.props.marginControl ? "-12px" : "0px",
              display: "flex",
              justifyContent:
                this.props.i18n.language == "ar" ? "flex-end" : "flex-start",
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  className="radiobtncolor"
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                  onClick={() => this.changeGender("M")}
                  checked={this.props.checked === "M"}
                  fontSize="small"
                />
              }
              label={this.props.t("amount")}
              labelPlacement={
                this.props.i18n.language == "ar" ? "Start" : "End"
              }
              style={{ order: this.props.i18n.language == "ar" ? 1 : 0 }}
            />
            <FormControlLabel
              value="female"
              control={
                <Radio
                  className="radiobtncolor"
                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                  onClick={() => this.changeGender("F")}
                  checked={this.props.checked === "F"}
                  fontSize="small"
                />
              }
              label={this.props.t("percentage")}
              labelPlacement={
                this.props.i18n.language == "ar" ? "Start" : "End"
              }
            />
          </div>
        </div>
      );
    } else if (data.type == "datepicker") {
      function onChange(date, dateString) {
        console.log(date, dateString);
      }
      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar={this.props.disableToolbar ? false : true}
                className="KeyboardDatePicker_class"
                autoOk={true}
                clearable={false}
                disableUnderline={true}
                disablePast={
                  this.props.disablePast ? this.props.disablePast : false
                }
                minDate={this.props.minDate && this.props.minDate}
                maxDate={this.props.maxDate && this.props.maxDate}
                variant="standard"
                format={this.props.format ? this.props.format : "dd-MMM-yyyy"}
                margin="normal"
                id="date-picker-inline"
                value={this.state.selecteddate}
                onChange={(date) => this.datepickerChange(date)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                shouldDisableDate={(date) =>
                  disableDates(
                    date,
                    this.props.blockDate ? new Date(this.props.blockDate) : null
                  )
                }
                disabled={this.props.disabled ? true : false}
                readOnly={this.props.readOnly ? true : false}
              />
            </MuiPickersUtilsProvider>
          </div>

          {data.error && (
            <div className="Errormsg">
              <span>{data.errmsg}</span>
            </div>
          )}
        </div>
      );
    } else if (data.type == "timepicker") {
      function onChange(date, dateString) {
        console.log(date, dateString);
      }

      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                value={this.state.selectedtime}
                onChange={(time) =>
                  this.timepickerChange(time, this.props.changeFormat)
                }
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </MuiPickersUtilsProvider>
            {data.error && (
              <div className="Errormsg">
                <span>{data.errmsg}</span>
              </div>
            )}
          </div>
        </div>
      );
    } else if (data.type == "select") {
      function onChange(value) {
        console.log(`selected ${value}`);
      }
      const { Option } = Select;
      function onBlur() {
        console.log("blur");
      }

      function onFocus() {
        console.log("focus");
      }

      function onSearch(val) {
        console.log("search:", val);
      }

      console.log(data.error);

      return (
        <div className="formdiv">
          <label className="labeltxt">{data.labelname}</label>

          {/* className={`${data.error && "brdred"} brdrcls`} */}

          {/* <Select
            className={`${data.error && "brdred"} ${
              data.error && "brdnone"
            } selectbox`}

            showSearch
            value={data.value ? data.value : "Select"}
            optionLabelProp="label"
            optionFilterProp="label"
            onChange={(value) =>
              this.props.changeData && this.props.changeData(value)
            }
          >
            {data.dropdown &&
              data.dropdown.length > 0 &&
              data.dropdown.map((item, index) => {
                return (
                  <Option
                    label={item[data.valuelabel]}
                    value={item[data.valuebind]}
                  >
                    {item[data.valuelabel]}
                  </Option>
                  // <Option  value={index} >{item}</Option>
                );
              })}
          </Select> */}

          <Select
            className={`${data.error && "brdred"} ${
              data.error && "brdnone"
            } selectbox`}
            showSearch
            value={
              data.value
                ? data.value
                : data.placeholder
                ? data.placeholder
                : "Select"
            }
            optionLabelProp="label"
            optionFilterProp="label"
            disabled={this.props.read_only}
            onChange={(value) =>
              this.props.changeData && this.props.changeData(value)
            }
          >
            {data.dropdown &&
              data.dropdown.length > 0 &&
              data.dropdown.map((item, index) => {
                return (
                  <Option
                    label={item[data.valuelabel]}
                    value={item[data.valuebind]}
                  >
                    {item[data.valuelabel]}
                  </Option>
                  // <Option  value={index} >{item}</Option>
                );
              })}
          </Select>
          {data.error && (
            <div className="Errormsg">
              <span>{data.errmsg}</span>
            </div>
          )}
        </div>
      );
    }
  };
  render() {
    const labelcss = require("./labelbox.css");
    return <div>{this.renderinput(this.props)}</div>;
  }
}
