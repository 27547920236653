import React from "react";
import "./BankCommission.css";
import MasterCard from "./../../Images/masterCard.png";
import ApplePay from "./../../Images/applePay.png";
import KNet from "./../../Images/kNet.png";
import { Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import { apiurl } from "../../App";
import { Spin } from "antd";

class BankCommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCard: false,
      kNet: false,
      applePay: false,
      spinner: false,
      paymentGateways: [],
      selectedGateways: {},
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
  }

  componentDidMount = () => {
    this.getVendorPaymentGateway();
    this.setState({ spinner: true });
    // this.state.creditCard = this.props.EditData[0].credit_card;
    // this.state.kNet = this.props.EditData[0].knet;
    // this.state.applePay = this.props.EditData[0].apple_pay;
    this.setState({ spinner: false });
  };

  handleUpdateClick() {
    const { selectedGateways } = this.state;
    console.log("selectedGateways", selectedGateways);

    const selectedIds = Object.keys(selectedGateways).filter(
      (id) => selectedGateways[id]
    );
    if (selectedIds.length != 0) {
      const data = {
        vendor_id: localStorage.getItem("vendorId") || "",
        vendor_type_id: 5,
        gateway_ids: selectedIds.join(","),
      };

      Axios.post(apiurl + "admin/updatePaymentGateways", data)
        .then((response) => {
          if (response.data.status === 200) {
            if (localStorage.training_center_language == "ar") {
              this.props.handleopenSuccess("!تم تحديث عمولة البنك بنجاح", true);
            } else {
              this.props.handleopenSuccess(
                "Bank Commission Updated Successfully!",
                true
              );
            }
          } else {
            if (localStorage.training_center_language == "ar") {
              this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
            } else {
              this.props.handleopenSuccess(
                "Bank Commission Updated Failed!",
                false
              );
            }
          }
          this.props.closemodal();
          this.props.ProfileGetApi();
        })
        .catch((error) => {
          if (localStorage.training_center_language == "ar") {
            this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
          } else {
            this.props.handleopenSuccess(
              "Bank Commission Updated Failed!",
              false
            );
          }
          console.error("API Error:", error);
        });
    } else {
      if (localStorage.training_center_language == "ar") {
        this.props.handleopenSuccess(".الرجاء اختيار طريقة الدفع", false);
      } else {
        this.props.handleopenSuccess(
          "Please Select any payment method.",
          false
        );
      }
    }
  }

  async getSelectedVendorPayment() {
    try {
      const data = {
        vendor_id: localStorage.getItem("vendorId") || "",
      };
      const response = await Axios.post(
        apiurl + "admin/getSelectedVendorPaymentGateway",
        data
      );
      return response.data.data || [];
    } catch (error) {
      console.error("Error fetching selected vendor payments:", error);
      return [];
    }
  }

  async getVendorPaymentGateway() {
    const data = {
      vendor_id: localStorage.getItem("vendorId") || "",
    };

    try {
      const response = await Axios.post(
        apiurl + "admin/getVendorPaymentGateway",
        data
      );

      if (response.data.data.length > 0) {
        const gateways = response.data.data;

        // Fetch selected gateways
        const selectedPayments = await this.getSelectedVendorPayment();

        // Create initial selection state
        const initialSelection = gateways.reduce((acc, gateway) => {
          acc[gateway.gateway_id] = selectedPayments.some(
            (selected) => selected.gateway_id === gateway.gateway_id
          );
          return acc;
        }, {});

        // Update state with gateways and initial selection
        this.setState({
          paymentGateways: gateways,
          selectedGateways: initialSelection,
        });
      }
    } catch (error) {
      console.error("Error fetching vendor payment gateways:", error);
    }
  }

  handleToggle(gatewayId) {
    this.setState((prevState) => ({
      selectedGateways: {
        ...prevState.selectedGateways,
        [gatewayId]: !prevState.selectedGateways[gatewayId],
      },
    }));
  }

  // updateCommission = () => {
  //   if (this.state.creditCard == true || this.state.kNet == true || this.state.applePay == true) {
  //     this.setState({ errorMsg: false });
  //     var updateValue = {
  //       trainingCenterId: localStorage.vendorId ? localStorage.vendorId : '',
  //       creditCard: this.state.creditCard == false ? 0 : 1,
  //       kNet: this.state.kNet == false ? 0 : 1,
  //       applePay: 0 ,
  //     };
  //     Axios({
  //       method: "POST",
  //       url: apiurl + "Sports/updateCommissionDetails",
  //       data: updateValue,
  //     })
  //       .then((response) => {
  //         if(response.data.status == 1){
  //           if (localStorage.training_center_language == 'ar') {
  //             this.props.handleopenSuccess("!تم تحديث عمولة البنك بنجاح", true);
  //           } else {
  //             this.props.handleopenSuccess("Bank Commission Updated Successfully!", true);
  //           }
  //         }else{
  //           if (localStorage.training_center_language == 'ar') {
  //             this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
  //           } else {
  //             this.props.handleopenSuccess("Bank Commission Updated Failed!", false);
  //           }
  //         }
  //         this.props.closemodal();
  //         this.props.ProfileGetApi();
  //       })
  //       .catch((error) => {
  //         if (localStorage.training_center_language == 'ar') {
  //           this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
  //         } else {
  //           this.props.handleopenSuccess("Bank Commission Updated Failed!", false);
  //         }
  //       });
  //   } else {

  //     // this.props.handleopenSuccess(t("Please Select any payment method"), false);
  //     if(localStorage.nurse_language=='ar'){
  //       this.props.handleopenSuccess(".الرجاء اختيار طريقة الدفع", false)
  //     }else{
  //       this.props.handleopenSuccess("Please Select any payment method.", false)
  //     }
  //   }
  // };

  render = () => {
    const { t } = this.props;
    const { paymentGateways, selectedGateways } = this.state;
    return (
      <>
        <Spin className="spinner_align" spinning={this.state.spinner}>
          <div
            className={`${
              this.props.i18n.language == "ar" ? "arCommissionManageDiv" : ""
            } commissionManageDiv`}
          >
            <div className="paymentMethodContainer">
              <div>
                <h1 className="paymentMethod">{t("paymentMethod")}</h1>
                <hr></hr>
              </div>
              <div className="imgContainer">
                {paymentGateways.map((method, idx) => (
                  <div key={idx} className="imgChildContainer">
                    <img src={method.commission_image} width={"28%"} alt="" />
                    <h3 className="color" style={{ display: "contents" }}>
                      {method.gateway_name}
                    </h3>
                    <Checkbox
                      checked={selectedGateways[method.gateway_id] || false}
                      onChange={() => this.handleToggle(method.gateway_id)}
                    ></Checkbox>
                  </div>
                ))}
              </div>
            </div>
            <div className="paymentMethodContainer">
              <div>
                <h1 className="paymentMethod">{t("commission")}</h1>
                <hr></hr>
              </div>
              <div className="commissionContainer">
                {paymentGateways.map((method, idx) => (
                  <div className="commisionDetails">
                    <h3 className="color">{method.gateway_name}</h3>
                    <h3 className="color">
                      {" "}
                      {method.commission_type === "PERCENTAGE"
                        ? `${method.commission}% Commission`
                        : `${method.commission} ${method.commission_type}`}
                    </h3>
                  </div>
                ))}
              </div>
            </div>
            <Grid
              item
              xs={12}
              md={12}
              className="profile_cont"
              style={{ padding: "12px" }}
            >
              <div
                style={{
                  marginRight: "27px",
                  display:
                    localStorage.training_center_language == "ar" ? "flex" : "",
                  gap:
                    localStorage.training_center_language == "ar" ? "10px" : "",
                  marginLeft:
                    localStorage.training_center_language == "ar" ? "27px" : "",
                }}
              >
                <Button
                  style={{ marginLeft: "30px" }}
                  className="cancel_button"
                  variant="contained"
                  onClick={this.props.onClose}
                >
                  {t("cancel")}
                </Button>

                <Button
                  className="update_button"
                  variant="contained"
                  color="primary"
                  onClick={this.handleUpdateClick}
                >
                  {t("update")}
                </Button>
              </div>
            </Grid>
          </div>
        </Spin>
      </>
    );
  };
}

export default BankCommission;
