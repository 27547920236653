import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import "./CustomerHistoryTable.css";
import Axios from "axios";
import { apiurl } from "../../App";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import excel from "../../Images/excel.svg";
import ReactExport from "react-data-export";
import ReactToPrint from "react-to-print";
import PrintData from "./printdata";
import ReactSVG from "react-svg";
import dateFormat from "dateformat";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import PDF from "../CommonPDF/PDF";
import downloadExcel from "../Common Excel/Excel";
import { Input, notification } from "antd";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const headRows = [
  { id: "sno", label: "S.No" ,typeDt: 'sno'},
  { id: "customer", label: "Member",typeDt: 'str' },
  { id: "age", label: "Age" },
  { id: "gender", label: "Gender",typeDt: 'str'  },
  { id: "phone", label: " Phone" },
  { id: "address", label: "Address" ,typeDt: 'str' },
];
class CustomerHistoryTable extends React.Component {
  state = {
    openview: false,
    tabledata: [],
    OpenViewData: [],
    getTableData: [],
    search: null,
    totalValue: "",
    props_loading: true,
    spinner: false
  };

  componentWillReceiveProps() { }

  createData = (parameter) => {
    var keys = Object.keys(parameter);
    var values = Object.values(parameter);
    var returnobj = {};

    for (var i = 0; i < keys.length; i++) {
      returnobj[keys[i]] = values[i];
    }
    return returnobj;
  };

 // data has empty this alert msg showed print pdf excel
 Notification = () => {
  notification.warning({
    message: "No data found",
    placement: "topRight",
  });
};

  modelopen = (data, id) => {
    var viewData = this.state.totalValue.find((OpenViewData) => {
      return OpenViewData.CustomerId === id;
    });
    if (data === "view") {
      this.setState({
        openview: true,
        OpenViewData: viewData,
      });
      this.props.DetailedCustomerHistory(id, viewData.Customername); //prop comming from index.js
    }
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };
  closemodal = () => {
    this.setState({ openview: false });
  };

  componentDidMount() {
    this.getTableData();
    // this.ViewTableData()

    this.intervalId = setInterval(() => {
      this.getTableData();
    }, 120000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }



  getTableData = () => {
    var self = this;
    Axios({
      method: "post",
      url: apiurl + "Nurse/getCustomerDetails",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "12"
      },
    })
      .then((response) => {
        var tabledata = [];
  
        // Use a Set to store CustomerIds and avoid duplicates
        const seenIds = new Set();
  
        response.data.data.map((val) => {
          if (!seenIds.has(val.CustomerId)) {
            seenIds.add(val.CustomerId); // Add unique id to Set
            tabledata.push({
              customer: val.Customername,
              age: val.patientage,
              gender: val.gender,
              phone: val.phone,
              address: val.patient_address == "null" ? "-": val.patient_address,//address,
              id: val.CustomerId,
            });
          }
        });
  
        this.setState({
          tabledata: tabledata,
          props_loading: false,
          totalValue: response.data.data,
        });
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
      });
  };
  


  // PDF FUNCTION
  generatepdf = () => {
    if (this.state.tabledata.length === 0) {
      alert("Table data is empty");
    } else {
      // alert("ee")
      const doc = new jsPDF("a3");
      var bodydata = [];

      



      this.state.tabledata.map((data, index) => {
        bodydata.push([
          // index + 1,
          // data.customer,
          // data.age,
          // data.gender,
          // data.phone,
          // data.address


          { content: index + 1, styles: { halign: 'center' } },
          { content: data.customer, styles: { halign: 'center' } }, // Left align for Member column
          { content: data.age, styles: { halign: 'center' } },
          { content: data.gender, styles: { halign: 'center' } }, // Left align for Member column
          { content: data.phone, styles: { halign: 'center' } },

          { content: data.address, styles: { halign: 'center' } }, // Left align for Member column




        ]);
      });
      doc.autoTable({
        beforePageContent: function (data) {
          doc.text("Uploaded Details", 15, 23); // 15,13 for css
        },
        margin: { top: 30 },
        showHead: "everyPage",
        theme: "grid",
        // head: [["S.No", "Member", "Age", "Gender", "Contact Number","Address"]],


        head: [
          [
            { content: "S.No", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
            { content: "Member", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
            { content: "Age", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
            { content: "Gender", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
            { content: "Contact Number", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
            { content: "Address", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },

          ],
        ],




        body: bodydata,
      });

      doc.save("UploadDetails.pdf");
    }
  };
  // PRINT FUNCTION
  generateprint = () => {
    this.setState({
      printComOpen: true,
    });
  };

  render() {
    const { Search } = Input;
    const { t } = this.props;

    const heading = ((localStorage.nurse_language == 'ar') ? [
      { id: "", label: t("tbl_head_remarks") },
      { id: "address", label: t("address") },
      { id: "phone", label: t("contact_number") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "age", label: t("tbl_head_age") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "", label: t("tbl_head_s_no") },
    ] : [
      { id: "", label: t("tbl_head_s_no") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "age", label: t("tbl_head_age") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "phone", label: t("contact_number") },
      { id: "address", label: t("address") },
      { id: "", label: t("tbl_head_remarks") },
    ])

    const Tabledatas = this.state.tabledata.filter((data) => {
      if (this.state.search === null) return data;
      else if (
        (data.customer !== null &&
          data.customer
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.phone != null &&
          data.phone
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.gender != null &&
          data.gender
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.address != null &&
          data.address
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.age != null &&
          data.age.toString().includes(this.state.search.toString()))
      ) {
        return data;
      }
    });
    // EXCEL FUNCTION
    var multiDataSetbody = [];
    this.state.tabledata.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.customer },
          { value: xldata.age },
          { value: xldata.gender },
          { value: xldata.phone },
          { value: xldata.address },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.customer,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.age,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.gender,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.phone,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.address,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
        ]);
      }
    });
    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Member",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Age",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Gender",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },

          {
            title: "Contact Number",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Address",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
        ],
        data: multiDataSetbody,
      },
    ];
    return (
      <>
        <div className="title_dashboard">
          <div className="title_header">{t("customer_history")} </div>
          <div
            style={{ fontSize: "16px", display: "flex", alignItems: "center" }}
          >

            <Search
              placeholder={t('search')}
              onSearch={(value) => console.log(value)}
              style={{ width: 150,  direction: this.props.i18n.language == "ar" ? "rtl" : "" , margin: "0px 10px 0px 0px" }}
              onChange={(e) => this.searchChange(e)}
              className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} `}

            />
            <div className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} icon_head`} style={{ direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}>
              {/* <ReactSVG
                src={pdf}
                onClick={this.generatepdf}
                style={{ marginRight:this.props.i18n.language=='ar'?"15px": "", marginLeft:this.props.i18n.language=='ar'?"": "15px" }}
              /> */}
              {/* <PDF
                weekMonthYearData={this.state.tabledata}
                headRows={headRows}
                filename={"Customerhistory Bookings"}
                headingTitle={'Customer history Bookings'}
                lang={this.props.i18n.language}
              />

              <img
                onClick={() => {
                  downloadExcel('Customer History', this.state.tabledata, headRows, '', [])
                }}
                src={excel}
                style={{ cursor: 'pointer', height: '24px'}}
              />
              {this.state.tabledata.length === 0 ? (
                <ReactSVG src={print} onClick={this.Notification} />
              ) : (
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
              )}
              <div style={{ display: "none" }}>
                <PrintData
                  printTableData={this.state.tabledata}
                  DetailedHistory={false}
                  ref={(el) => (this.componentRef = el)}
                />}
              {</div> */}
            </div>
          </div>
        </div>
        <div className="customer_history_table_div">
          <Tablecomponent
            heading={heading}
            rowdata={Tabledatas.length === 0 ? [] : Tabledatas}
            props_loading={this.state.props_loading}
            DeleteIcon="close"
            EditIcon="close"
            modelopen={(e, id) => this.modelopen(e, id)}
            HistoryIcon="close"
            LocationOnIcon="close"
            t={t}
            i18n={this.props.i18n}
          />
        </div>
      </>
    );
  }
}
export default CustomerHistoryTable;