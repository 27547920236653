import React, { Component,useState  } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import NotfoundIcon from "../../Images/NotFound.svg";
import { Icon, message, Popconfirm } from "antd";
import { Spin } from "antd";
// standard icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import more from "../../Images/more.svg";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HistoryIcon from "@material-ui/icons/History";
import Rating from "react-rating-stars-component";
import Nursereview from "../../components/NurseReviewReport/ReviewReportMaster.jsx";
import classNames from "classnames";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
import { formatMoney } from "../../../src/App.js";
import "./TableComp.css";
import EyeIcon from "../../Images/eye_icon.svg";
import ReactSVG from "react-svg";


// const [statusCheck, setStatusCheck] = useState(null);



// "../../helpers/TableComponent/TableComp"
// import { arrayRemoveAll } from "redux-form";
const axios = require("axios");

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    t
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headRows = [
    { id: "sno", label: "S.No" },
    { id: "patient", label: "Customer" },
    { id: "gender", label: "Gender" },
    { id: "age", label: "Age" },
    { id: "time", label: "Time" },
    { id: "service", label: "Service" },
    { id: "action", label: "Action" },
  ];


  return (
    <TableHead className={props.alignheading}>
      <TableRow>
        {props.heading.map((row) => (
          <TableCell
            style={{ fontWeight: "40px" }}
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            {/* {row.label} */}




            {row.label === "Fee (KWD)" ? (
              <span>
                Cost<span style={{ fontSize: "10px", fontWeight: '500' }}> ({localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"})</span>
              </span>
            ) : row.label === "Exp (yrs)" ? (
              t("tbl_head_experience") == "Exp (yrs)" ? <span>
                Exp<span style={{ fontSize: "10px", fontWeight: '500' }}> (yrs)</span>
              </span> : <span>{t("tbl_head_experience")}</span>
            ) : row.label === "Age (yrs)" ? (
              t("tbl_head_age") == "Age (yrs)" ? <span>
                Age<span style={{ fontSize: "10px", fontWeight: '500' }}> (yrs)</span>
              </span> : <span>{t("tbl_head_age")}</span>
            ) :row.label === "Age (yrs)" ? (
              t("tbl_head_age") == "Age (yrs)" ? <span>
                Age<span style={{ fontSize: "10px", fontWeight: '500' }}> (yrs)</span>
              </span> : <span>{t("tbl_head_age")}</span>
            ) :

              (
                <span>{row.label}</span>
              )}




            {/* <TableSortLabel
              active={orderBy === row.id}
              direction={order}
           //   onClick={createSortHandler(row.id)}
              // hideSortIcon={
              //   row.label.toLowerCase() == "s.no" ||
              //   row.label.toLowerCase() == "action"
              //     ? true
              //     : false
              // }
              hideSortIcon={row.id.toLowerCase() === "" ? true : false}
            >
              {row.label}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired, // Assuming `t` is a translation function

};

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;
    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,

};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

export default class Tablecomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "",
      open: false,
      orderBy: "media_title",
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 5,
      viewmodal: false,
      rows: this.props.rowdata,
      rowsddddddd: this.props.rowdata,
      pacccckedt: this.props.podss,
      viewdata: null,
      type: "",
      title: "",
      rowData: this.props.rowdata || []
    
    };
    console.log("sun", this.state.rows);
  }

  handleRequestSort = (event, property) => {
    if (property != "") {
      const isDesc =
        this.state.orderBy === property && this.state.order === "desc";
      this.setState({ order: isDesc ? "asc" : "desc" });
      this.setState({ orderBy: property });
    }
  };

  closemodal = () => {
    this.setState({ view: false, DeleteView: false });
  };
  handleCheckboxChange = (index) => {
    alert(index)
    console.log("index",index)
    return ;
    this.setState((prevState) => {
      const updatedRowData = [...prevState.rowData];
      updatedRowData[index].is_active = updatedRowData[index].is_active === 1 ? 0 : 1;
      return { rowData: updatedRowData };
    });
  };
  handleClick = (event, name) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected.push(this.state.selected, name);
    } else if (selectedIndex === 0) {
      // newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      // newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      // newSelected = newSelected.concat(
      //   selected.slice(0, selectedIndex),
      //   selected.slice(selectedIndex + 1),
      // );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  handleChangeDense(event) {
    this.setState({ dense: event.target.checked });
  }

  receiveapprovaldata = (data, data1) => {
    if (data1 == 1) {
      this.setState({ viewmodal: false });
      message.success("Your Leave Approved");
      this.loadVendorDetails();
    } else if (data1 == 2) {
      this.setState({ viewmodal: false });
      message.success("Your Leave Rejected");
      this.loadVendorDetails();
    }
  };

  receivedocdelete = (data) => {
    if (data.status == 0) {
      this.setState({ viewmodal: false });
      message.success(data.msg);
      this.loadDoctorDetails();
    }
  };
  sendapprovadata = (data) => {
    if (data.status == 0) {
      this.setState({ viewmodal: false });
      message.success(data.msg);
      this.loadDoctorDetails();
    }
  };

  goToNurse() {
    debugger;
    localStorage.setItem("particularReview", 1);
    this.props.modelopen("view", 136);
    // this.props.modelopen()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      rows: newProps.rowdata,
      noDataRev: newProps.noData

    });
  }

  render() {
    const isSelected = (name) => this.state.selected.indexOf(name) !== -1;
    const { rows, rowsPerPage, page } = this.state;
    const { t } = this.props;
    return (
      <Spin className="spinner_align" spinning={this.props.props_loading}>
        <div className={`VendorDetailsDiv ${this.props.tablemasterclass}`}>
          <Paper className="paper">
            <div className="tableWrapper">
              {this.state.pacccckedt == "killer" ? (
                <Table
                  className="table"
                  aria-labelledby="tableTitle"
                  size={this.state.dense ? "small" : "medium"}
                >
                  {/* <TableHead>Testing</TableHead>
                  <TableHead>Testing</TableHead>
                  <TableHead>Testing</TableHead> */}
                  <EnhancedTableHead
                    // numSelected={this.state.selected.length}
                    // order={this.state.order}
                    // orderBy={this.state.orderBy}
                    // // onSelectAllClick={this.handleSelectAllClick}
                    heading={this.props.heading}
                    // onRequestSort={this.handleRequestSort}
                    // rowCount={this.state.rows && this.state.rows.length}
                    // alignheading={this.props.alignheading}
                    t={t}
                  />

                  {/* {this.state.data.map((listValue, index) => {
                  return (
                    <tr key={index}>
                      <td>{listValue.id}</td>
                      <td>{listValue.title}</td>
                      <td>{listValue.price}</td>
                    </tr>
                  );
                })} */}

                  <TableBody>
                    {this.props.rowdata.map((listValue, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index}</TableCell>
                          <TableCell>{listValue.patient}</TableCell>
                          <TableCell>{listValue.gender}</TableCell>
                          <TableCell>{listValue.address}</TableCell>
                          <TableCell>{listValue.nurse_name}</TableCell>
                          <TableCell>{listValue.is_active}</TableCell>

                          <TableCell>
                            <Rating
                              count={5}
                              value={listValue.nurseRating}
                              size={24}
                              activeColor="#ff0068"
                              edit={false}
                              isHalf={true} // Set to true if you want to display half stars
                              classNames={"star-rating"} // Apply a custom class for styling// Set to true if you want to enable editing
                            />
                          </TableCell>
                          <TableCell>{listValue.nursefeedback}</TableCell>
                          {/* <TableCell><button onClick={() => { this.goToNurse() }}>Click</button></TableCell> */}
                          {/* 
                         <TableCell>Good Evening</TableCell>
                         <TableCell><div class="bg">⭐⭐⭐</div></TableCell>
                         </TableRow>
                         <TableRow>
               
                         
                         </TableRow>
                         <TableRow>
                         <TableCell>Normal Evening</TableCell>
                         <TableCell>Normal Evening</TableCell> */}



                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Table
                  className="table"
                  aria-labelledby="tableTitle"
                  size={this.state.dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={this.state.selected.length}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    // onSelectAllClick={this.handleSelectAllClick}
                    heading={this.props.heading}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.rows && this.state.rows.length}
                    alignheading={this.props.alignheading}
                    t={t}
                  />
                  {
                    this.props.isHeadingButton &&
                    <TableHead className="buttonHeadtitle">
                      <TableRow>
                        {this.props.HeadingButton.map((row, id) => (
                          <TableCell key={id}><span className={row.label ? (row.type == 'tomshare' ? "HeadingButton2" : "HeadingButton") : ""}>{row != '' ? formatMoney(Number(row.label)) : row.label}</span></TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  }
                  <TableBody>
                    {this.state.rows.length === 0 && (
                      <TableCell className={"noFoundIconCenter"} colSpan={13}>
                        <img src={NotfoundIcon} />
                        <div>{(this.state.noDataRev === "" || this.state.noDataRev === null || this.state.noDataRev === undefined) ? t("no_data_found") : this.state.noDataRev}</div>

                      </TableCell>
                    )}
                    {stableSort(
                      this.state.rows,
                      getSorting(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                      )
                      .map((row, index, item) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              this.handleClick(event, row.name)
                            }
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {localStorage.nurse_language == "en" ? (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {this.state.rowsPerPage * this.state.page -
                                  1 +
                                  index +
                                  2}
                              </TableCell>
                            ) : this.props.actionclose === "close" ? null : (
                              <TableCell
                                className={`${this.props.tableicon_align} d-flex `}
                              // className={`${this.props.tableicon_align}  `}
                              style={{alignItems:this.props.i18n.language == "ar"? "center": ""}}
                              >

                                {this.props.ShowTicketButton &&
                                  row.status != "Resolved" &&
                                  row.status != "Pending" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      float: "right",
                                      position: "relative",
                                      bottom: "5px",
                                      left: "-35px ",
                                      direction:this.props.i18n.language == "ar"? "rtl": ""
                                    }}
                                  >
                                    <div
                                      className={
                                        row.status == "Reopen"
                                          ? "ticket_reopen"
                                          : "ticket_reopen"
                                      }
                                      onClick={() =>
                                        this.props.modelopen(
                                          "reopen",
                                          row.ticket_id
                                        )
                                      }
                                    >
                                      {" "}
                                      {t("reopen")}
                                    </div>
                                    <div
                                      className="ticket_resolved"
                                      onClick={() =>
                                        this.props.modelopen(
                                          "resolved",
                                          row.ticket_id
                                        )
                                      }
                                    >
                                      {" "}
                                      {t("resolved")}
                                    </div>
                                  </div>
                                ) : null}
                                {this.props.DeleteIcon === "close" ? null : (
                                  <DeleteIcon
                                    className="tabledelete_icon"
                                    onClick={() =>
                                      this.props.deleteopen("delete", row.id)
                                    }
                                  />
                                )}
                                {this.props.HistoryIcon === "close" ? null : (
                                  <HistoryIcon
                                    className="tablehistory_icon"
                                    onClick={() =>
                                      this.props.modelopen("history", row.id)
                                    }
                                  />
                                )}
                                {this.props.EditIcon === "close" ? null : (
                                  <EditIcon
                                    className="tableedit_icon"
                                    onClick={() =>
                                      this.props.modelopen("edit", row.id)
                                    }
                                  />
                                )}
                                {this.props.LocationOnIcon ===
                                  "close" ? null : row.isCheckin == "true" ? (
                                    <LocationOnIcon
                                      className="tablelocation_icon"
                                      onClick={() =>
                                        this.props.modelopen("location", row.id)
                                      }
                                    />
                                  ) : null}
                                {this.props.VisibilityIcon === "close" ? null : (
                                  <img
                                    src={more}
                                    className="tableeye_icon"
                                    onClick={() =>
                                      this.props.modelopen("view", row.id)
                                    }
                                  />
                                )}
                              </TableCell>

                            )}

                            {[row].map((data, index) => {
                              var keys =
                                localStorage.nurse_language == "ar"
                                  ? Object.keys(data).reverse()
                                  : Object.keys(data);
                              var arrval = [];
                              let first_value =
                                localStorage.nurse_language == "ar" ? 1 : 0;
                              let condition =
                                localStorage.nurse_language == "ar"
                                  ? this.props.actionclose === "close" ||
                                    this.props.LocationOnIcon === "close"
                                    ? keys.length
                                    : keys.length - 1
                                  : this.props.actionclose === "close" ||
                                    this.props.LocationOnIcon === "close"
                                    ? keys.length - 1
                                    : keys.length - 2;
                                    for (var m = first_value; m < condition; m++) {
                                      const key = keys[m]; // Get the key
                                        arrval.push(
                                          <TableCell key={`${data.id}-${m}`}>
                                            {data[key]}
                                          </TableCell>
                                        );
                                    }                                    
                              return arrval;
                            })}

                            {localStorage.nurse_language == "en" ? (
                              this.props.actionclose === "close" ? null : (
                       
                       <>
                        {/* <TableCell>
                          {console.log("llllzz", this.props.rowdata)}
                          <input
                            type="checkbox"
                            checked={this.props.rowdata.map((data,index) => data).is_activeStatus == 1}
                            onChange={() => this.handleCheckboxChange()}
                          />
                          Active
                        </TableCell> */}



        {/* <TableCell>
          <input
            type="checkbox"
            className="inactivecheckbox"
            // checked={this.state.activeStatus === 0}
            // onChange={() => this.handleCheckboxChange(0)}
          />
          Inactive
        </TableCell> */}
      
                                <TableCell
                                  // className={`${this.props.tableicon_align} d-flex `}
                                  className={`${this.props.tableicon_align} `}
                                >
                                  {this.props.VisibilityIcon ===
                                    "close" ? null : (
                                    <img
                                      src={more}
                                      className="tableeye_icon"
                                      onClick={() =>
                                        this.props.modelopen("view", row.id)
                                      }
                                    />
                                  )}
                                  {this.props.ShowTicketButton &&
                                    row.status != "Resolved" &&
                                    row.status != "Pending" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        float: "right",
                                        position: "relative",
                                        bottom: "5px",
                                        // left:'80px',
                                      }}
                                    >
                                      <div
                                        className={
                                          row.status == "Reopened"
                                            ? "ticket_reopen reopen_status"
                                            : "ticket_reopen"
                                        }
                                        onClick={() =>
                                          this.props.modelopen(
                                            "reopen",
                                            row.ticket_id
                                          )
                                        }
                                      >
                                        {" "}
                                        {t("reopen")}
                                      </div>
                                      <div
                                        className="ticket_resolved"
                                        onClick={() =>
                                          this.props.modelopen(
                                            "resolved",
                                            row.ticket_id
                                          )
                                        }
                                      >
                                        {" "}
                                        {t("resolved")}
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.props.EditIcon === "close" ? null : (
                                    <EditIcon
                                      className="tableedit_icon"
                                      onClick={() =>
                                        this.props.modelopen("edit", row.id)
                                      }
                                    />
                                  )}
                                  {this.props.LocationOnIcon ===
                                    "close" ? null : row.isCheckin == "true" ? (
                                      <LocationOnIcon
                                        className="tablelocation_icon"
                                        onClick={() =>
                                          this.props.modelopen("location", row.id)
                                        }
                                      />
                                    ) : null}
                                  {this.props.HistoryIcon === "close" ? null : (
                                    <HistoryIcon
                                      className="tablehistory_icon"
                                      onClick={() =>
                                        this.props.modelopen("history", row.id)
                                      }
                                    />
                                  )}
                                  {this.props.DeleteIcon === "close" ? null : (
                                    <DeleteIcon
                                      className="tabledelete_icon"
                                      onClick={() =>
                                        this.props.deleteopen("delete", row.id)
                                      }
                                    />
                                  )}
                                </TableCell>
                                </>
                              )
                            ) : (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {this.state.rowsPerPage * this.state.page -
                                  1 +
                                  index +
                                  2}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>

                </Table>
              )}
            </div>
            {/* {this.props.grandtotal === "total" ? (
              <div>
                <div className="revenueTotal">
                  <span>
                    {" "}
                    {t("grand_total_with_colon")}{" "}
                    {`${this.props.grandtotal_value}`}{" "}
                    <span style={{ fontSize: "12px" }}>{t("kwd")}</span>
                  </span>
                </div>
              </div>
            ) : (
              ""
            )} */}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                native: true,
              }}
              component="div"
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActionsWrapped}
              labelRowsPerPage={t("rows_per_page")}
            />
          </Paper>
        </div>
      </Spin>
    );
  }
}
