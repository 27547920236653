import React, { Component } from "react";
import Logo from "../../Images/logo_new.svg";
import TextField from "@material-ui/core/TextField";
import "./Login.css";
import Grid from "@material-ui/core/Grid";
import Eye from "../../Images/eye.svg";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Inbox from "../../Images/inbox.svg";
import Nurse_Login from "../../Images/nurse_login_new.jpg";
import Axios from "axios";
import { apiurl } from "../../App";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import ContractApproval from "./ContractApproval";
import ApprovalPopup from "./Approvalpopup";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import MuiDialogContent from "@material-ui/core/DialogContent";
import i18n from "../../i18n/i18n";
import { withTranslation } from "react-i18next";
import { initialize } from "../../App";
import success from "../../Images/Group23311.svg" 
import logo from "../../Images/logo_new.png";
import ShowNotification from "../ShowNotification/ShowNotification";
import { Visibility, VisibilityOff } from "@material-ui/icons";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      hidden: true,
      draweropen: false,
      email: "",
      iserror: false,
      error_msg: "",
      nurse_vendor_id: 0,
      contract_terms: "",
      commission: "",
      contract_id: 0,
      vendor_id: 0,
      openContractApproval: false,
      openApprovalPopup: false,
      openSuccesspopup: false,
      RegisterationPushNotification: false,
      vendor_name: "",
      login_data: {},
      nurseIdValue: 0
    };
    if((localStorage.getItem('nurse_vendor_id') != '') && (localStorage.getItem('nurse_vendor_id') != 0)){
      this.state.nurseIdValue = localStorage.getItem('nurse_vendor_id');
      if(this.state.nurseIdValue != 0 && this.state.nurseIdValue != null){ this.clearVendorWebToken(); }
    }
  }
  clearVendorWebToken = () => {
    let requestdata = {
      "vendor_id": this.state.nurseIdValue
    }
    Axios({
      method: "POST",
      url: apiurl + "admin/clearVendorWebToken",
      data: requestdata,
    })
      .then((response) => {
        this.state.nurseIdValue = 0;
        window.localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  toggleshow = () => {
    if(this.state.hidden === false){
      this.setState({ hidden: true });
    }else{
      this.setState({ hidden: false });
    }
  };
  onchange = (e) => {
  if(e.target.value == ""){
    this.setState({
      password: "",
      iserror: true,
      error_msg: "Please Fill Password Field",
    });
  }else{
    this.setState({ password: e.target.value, iserror: false,});
  }
  };

  draweropen = () => {
    if (this.state.email != "" && this.state.password != "") {
      var obj_data = {
        email: this.state.email,
        password: this.state.password,
        module: "nurse",
      };
      Axios({
        method: "POST",
        url: apiurl + "admin/LoginWeb",
        data: obj_data,
      })
      .then((response) => {
        if (response.data.status == 1) {
          if (response.data.data.length > 0) {

            const ContracatStartDate = response.data.data[0].contracatStartDate;
            const ContracatEndDate = response.data.data[0].contracatEndDate;
            const CurrentDBDate = response.data.data[0].Currentdate;

            localStorage.setItem("refreshtoken", response.data.refreshtoken);
            localStorage.setItem("tokenHeader", response.data.token);
            localStorage.setItem("user_name", response.data.data[0]["user_name"]);
            localStorage.setItem("Vemail", response.data.data[0]["email"]);
            localStorage.setItem("training_center_language", "en");
            localStorage.setItem("vendorId", response.data.data[0]["vendor_id"]);
            localStorage.setItem("nurse_language", "en");
            // initialize();
            const responseData = response.data.data[0];
            localStorage.nurse_language = "en";

            // if (response.data.data.length > 0) {
            //   this.setState({ vendorId: response.data.data[0]["vendor_id"], login_data: response.data.data[0] }, () => {
            //     this.getContractDetail()
            //   })
            // }
            if (ContracatStartDate <= CurrentDBDate && CurrentDBDate <= ContracatEndDate) {

              if ((responseData.contract_status.toUpperCase() == "P" || responseData.contract_status.toUpperCase() == "A") && responseData.admin_upload_status == 1) {
                this.setState(
                  {
                    nurse_vendor_id: responseData.vendor_id,
                    login_data: responseData,
                  },
                  () => {
                    this.getContractDetail();
                  }
                );
              } else if ((responseData.contract_status.toUpperCase() == "P" || responseData.contract_status.toUpperCase() == "R") || responseData.admin_upload_status == 0) {
                this.handleopenSuccess("Please contact tom admin.", false);
              }

            }
            else {
              this.setState({
                iserror: true,
                error_msg: "Please contact tom admin.",
              });
            }
          }
        }

        else {
          this.setState({
            iserror: true,
            error_msg: "Email Or Password wrong",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    if (this.state.email == "" && this.state.password == "") {
      this.setState({
        iserror: true,
        error_msg: "Please Fill Email And Password Field",
      });
    } else if (this.state.email == "") {
      this.setState({ iserror: true, error_msg: "Please Fill Email Field" });
    } else if (this.state.password == "") {
      this.setState({
        iserror: true,
        error_msg: "Please Fill Password Field",
      });
    }
  }
};
  fogotpush = () => {
    this.props.history.push("/forgot");
  };

  getContractDetail() {
    Axios({
      method: "POST",
      url: apiurl + "admin/getVendorContractWeb",
      data: {
        vendor_id: this.state.nurse_vendor_id,
      },
    })
      .then((response) => {
        this.sendToken(this.state.nurse_vendor_id);
        this.changeLanguage(this.state.nurse_vendor_id);
        this.setState({ contractDetails: response });
        if (response.data.data[0].ErrorCode == "9999") {
          localStorage.setItem("nurse_vendor_id", this.state.nurse_vendor_id);
          localStorage.setItem(
            "login_data",
            JSON.stringify(this.state.login_data)
          );
          this.setState({ draweropen: true });
          this.props.history.push("/Home/dashboard");
        } else if (response.data.data[0].ErrorCode == "9998") {
          this.setState({
            openApprovalPopup: true,
            contract_terms: response.data.data[0].contract_terms,
            commission: response.data.data[0].commission,
            contract_id: response.data.data[0].id,
            vendor_id: response.data.data[0].nurse_vendor_id,
            termsConditionFile: response.data.data[0].terms_and_condition_file,
            signedContractFile: response.data.data[0].signed_contract_file,
            tomEsign: response.data.data[0].e_signature_file,
          });
        } else {
          this.setState({
            iserror: true,
            error_msg: "Please contact admin",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  contractChange(isapporve) {
    if (isapporve) {
      this.setState({ openContractApproval: false, openApprovalPopup: true });
    } else {
      this.setState({ openContractApproval: false });
    }
  }

  approvalpopupClose() {
    setTimeout(() => {
      const type = localStorage.getItem("approvalpopupClose");
      const VendorName = localStorage.getItem("VendorName");
      if (type == "close") {
        this.setState({ openApprovalPopup: false });
      } else if (type == "accept") {
        this.setState({ openApprovalPopup: false, openSuccesspopup: true });
        this.setState({RegisterationPushNotification:true});
        this.setState({vendor_name:VendorName});
        setTimeout(() => {
          this.setState({RegisterationPushNotification:false});
          localStorage.removeItem('VendorName');
        }, 6000);
      }
    }, 500);
    // this.setState({ openApprovalPopup: false, openSuccesspopup: true })
  }
  rejectapprovalpopupClose = () => {
    this.setState({ openApprovalPopup: false, openRejectSuccessPopup: true, issuccess: true })
  }
  doLogin() {
    localStorage.setItem("nurse_vendor_id", this.state.nurse_vendor_id);
    localStorage.setItem("login_data", JSON.stringify(this.state.login_data));
    this.setState({ draweropen: true, openSuccesspopup: false });
    this.props.history.push("/Home/dashboard");
  }
  handleCloseSuccess = () => {
    this.setState({ openRejectSuccessPopup: false, visible: false });
  };
  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  }
  sendToken = (id) => {
    Axios({
      method: "POST",
      url: apiurl + "admin/insertvendorwebtoken",
      data: {
        "vendor_id": id,
        "token": localStorage.getItem("TokenFCM")
      },
    })
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
}

changeLanguage = (id) => {

Axios({
  method: "POST",
  url: apiurl + "admin/insertvendorweblanguage",
  data: {
    "vendor_id": id,
    "language": "en"
  },
})
  .then((response) => {
    console.log("update token response", response);
  })
  .catch((error) => {
    console.log(error);
  });
}
  render() {
    const iconStyle = {
      fontSize: '25px', // Change the size
      color: '#707070', // Change the color
    };
    const { t } = this.props;
    return (
      <div>
        {this.state.draweropen === false && (
          <div className="pharmacy_login_container">
            <Grid container>
              <Grid item xs={12} md={7} className="pharmacy_image_grid">
                <div className="pharmacy_image_container">
                  <div className="pharmacy_image_div">
                    <div className="pharmacy_image_login">
                      <div style={{display:'flex',alignItems:'center',justifyContent:'start'}}>
                        <img
                          src={Nurse_Login}
                          alt="1"
                          className="pharmacy_image"
                        />
                        <div>
                        <p className="pharmacy_textData">{t("nurse_login_txt")}</p>
                        </div>
                        {/* <p className="pharmacy_textData">{t("nurse_login_txt")}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={5} className="pharmacy_grid_container">
                <div className="pharmacy_main_container">
                  <div
                    className={`${
                      this.props.i18n.language == "ar" ? "arabic_cls" : ""
                    } pharmacy_paper_div `}
                  >
                    <div className="pharmacy_text_container">
                      <div className="logo_container">
                        <div className="logo_div">
                          <img className="logo_image" src={Logo} />
                        </div>
                      </div>
                      <div className="pharmacy_Welcometext-container">
                        <p className="Welcometext">{t("login_welcome_text")}</p>
                      </div>
                      <div className="pharmacy_email_container">
                        <TextField
                          type="text"
                          placeholder="Enter Email"
                          label="EMAIL"
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value.trim(),
                              iserror: false,
                            });
                          }}
                          value={this.state.email}
                          inputProps={
                            this.props.i18n.language == "ar"
                              ? { style: { textAlign: "right" } }
                              : {}
                          } // the change is here
                          InputProps={
                            this.props.i18n.language == "ar"
                              ? {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton>
                                        <img
                                          className="inbox_icon"
                                          src={Inbox}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }
                              : {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <img
                                          className="inbox_icon"
                                          src={Inbox}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }
                          }
                          style={{
                            '&::placeholder': {
                              color: 'red', // Customize the color as needed
                              // Add any other placeholder styles here
                            },
                          }}
                        />
                      </div>

                      <div className="password_container">
                        <TextField
                          type={this.state.hidden ? "password" : "text"}
                          onChange={this.onchange}
                          value={this.state.password}
                          placeholder="Enter Password"
                          className="trrainer_password"
                          label={t("password")}
                          inputProps={
                            this.props.i18n.language == "ar"
                              ? { style: { textAlign: "right" } }
                              : {}
                          } // the change is here
                          InputProps={
                            this.props.i18n.language == "ar"
                              ? {
                                  startAdornment: (
                                    <InputAdornment>
                                      <IconButton onClick={this.toggleshow} style={{ left: "-4px" }}>
                                      {this.state.hidden ? <VisibilityOff style={iconStyle} /> : <Visibility style={iconStyle} />}
                                    </IconButton>
                                    </InputAdornment>
                                  ),
                                }
                              : {
                                  endAdornment: (
                                    <InputAdornment>
                                      <IconButton onClick={this.toggleshow}>
                                      {this.state.hidden ? <VisibilityOff style={iconStyle} /> : <Visibility style={iconStyle} />}
                                    </IconButton>
                                    </InputAdornment>
                                  ),
                                }
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: this.state.iserror ? "block" : "none",
                          color: "red",
                          textAlign: "center",
                          marginTop: "5px",
                          fontSize:"14px"
                      
                        }}
                      >
                        {this.state.error_msg}
                      </div>
                      <div className="login_button_container">
                        <button className="login" onClick={this.draweropen}>
                          {t("login")}
                        </button>
                      </div>
                      <div className="cancel_container">
                        <p className="cancelbutton" onClick={this.fogotpush}>
                          {t("login_forgot_password")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}

        {/* <Dialog
          className={`${
            this.props.i18n.language == "ar" ? "arabic_cls" : ""
          } Dialogmodal `}
          // onClose={this.handleClose}
          open={this.state.openContractApproval}
          aria-labelledby="customized-dialog-title"
          maxWidth={"xs"}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <MuiDialogTitle disableTypography>
            <Typography variant="h6" className="contatct_approve_head">
              {" "}
              {t("contract_approval")}{" "}
            </Typography>
          </MuiDialogTitle>
          <MuiDialogContent dividers className="DialogContent">
            <ContractApproval
              {...this.state}
              contractChange={this.contractChange.bind(this)}
              t={t}
              i18n={this.props.i18n}
            ></ContractApproval>
          </MuiDialogContent>
        </Dialog> */}

        {this.state.openApprovalPopup && (
          <ApprovalPopup {...this.state} approvalpopupClose={() => {   this.approvalpopupClose();}}
          rejectapprovalpopupClose={() => { this.rejectapprovalpopupClose() }}
            response={this.state.contractDetails}
            t={t}
            i18n={this.props.i18n}
          ></ApprovalPopup>
        )}
        <Dialog
          className={`${
            this.props.i18n.language == "ar" ? "arabic_cls" : ""
          } Dialogmodal contract_alert_popup `}
          // onClose={this.handleClose}
          open={this.state.openSuccesspopup}
          aria-labelledby="customized-dialog-title"
          maxWidth={"xs"}
          fullWidth={true}
          disableBackdropClick={true}
        >
          {/* <MuiDialogTitle disableTypography >
            <Typography variant="h6" className="titillium_font">{t('contract_alert')}</Typography>
          </MuiDialogTitle> */}
          <div>
            <div style={{display:'flex',justifyContent:'center',marginTop:'3.5rem'}}>
              <img src={success} ></img>
            </div>
            <div className="succesimg" >{t("success_head")}</div>
            <div
              style={{
                marginTop: "1rem",
                textAlign: "center",
                marginBottom: "1rem",
                fontSize: "16px",
                color:"black"
              }}
            >
              {t("account_activate_success")}
            </div>
            <div
              className="contract_approve_btn_div"
              style={{ marginBottom: "1rem" }}
            >
              <button
                className="loginbutton_1"
                onClick={() => {
                  this.doLogin();
                }}
              >
                {" "}
                {t("ok")}{" "}
              </button>
            </div>
          </div>
        </Dialog>
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t} i18n={this.props.i18n}>
        </ShowNotification>
        {this.state.RegisterationPushNotification &&
          <div className="pushNotification_body">
            <div className="notification_img_container">
              <img src={logo} className='notification_imgs' />
            </div>
            <div className="pushNotification_content">
              <p className="notification_text_head"><b>Registration Notification - Nurse</b></p>
              <div className="notification_text_body">
                Congratulations, {this.state.vendor_name}! You're officially registered with TOM.
              </div>
            </div>
          </div> 
        }
      </div>
    );
  }
}
export default withTranslation()(Login);