import React from "react";
import { Select } from "antd";
import Moment from "react-moment";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import ReactSVG from "react-svg";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./NursebookedTable.css";
import NurseView from "../NurseView/Nurse_view";
import NurseLocationModal from "./NurseLocationModal";
import Axios from "axios";
import { apiurl } from "../../App";
import dateFormat from "dateformat";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-data-export";
import ReactToPrint from "react-to-print";
import PrintData from "./printdata";
import dateformat from "dateformat";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import Filter from "../../Images/filter.svg";
import AdvanceFilter from "../AdvanceFillter/AdvanceFilter";
import PDF from "../CommonPDF/PDF";
import downloadExcel from "../Common Excel/Excel";
import { Input, notification } from "antd";
import { formatMoney } from "../../App";
import logos from "../../Images/Logos.png";
import walkin from "../../Images/walkin.svg";

var moment = require("moment");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const headRows = [
  { id: "sno", label: "S.No", typeDt: 'sno' },
  { id: "customer", label: "Member", typeDt: 'str' },
  { id: "appointmenttype", label: "Appointment Type", typeDt: 'str' },
  { id: "paymenttype", label: "Payment Type", typeDt: 'str' },
  { id: "nursename", label: "Nurse", typeDt: 'str' },
  { id: "working_hours", label: "Duty Hours" },
  // { id: "booked_date", label: "Booked On", type: "date", typeDt: 'date' },
  { id: "fromdate", label: "From", },
  { id: "todate", label: "To", },
  // { id: "status", label: "Status" },
  { id: "totalcost", label: "Cost", typeDt: 'amt',type: "amt" },
];
class NursebookedTable extends React.Component {
  state = {
    openview: false,
    search: null,
    props_loading: true,
    tabledata: [],
    tabledata1: [],
    totalValue: "",
    OpenViewData: [],
    spinner: false,
    locationdata: [],
    selected_nurseId: 0,
    selected_working_hrs: "",
    open_filter: false,
    // from_date:dateformat(new Date(), "yyyy-mm-dd"),
    // to_date:dateformat(new Date(), "yyyy-mm-dd")
    from_date: "", //dateformat(new Date(), "yyyy-mm-dd"),
    to_date: "", //dateformat(new Date(), "yyyy-mm-dd")
  };

  // data has empty this alert msg showed print pdf excel
  Notification = () => {
    notification.warning({
      message: "No data found",
      placement: "topRight",
    });
  };

  modelopen = (data, id) => {
    debugger
    if (data === "view") {
      // alert(id)
      // var OpenViewData = this.state.totalValue.filter((OpenViewData)=>{
      //    return OpenViewData.NurseId===id})
      this.setState({
        openview: true,
        OpenViewData: this.state.totalValue[id],
      });
    } else if (data === "edit") {
      this.setState({ editopen: true });
    } else if (data === "location") {
      this.setState({
        locationopen: true,
        locationdata: this.state.totalValue[id],
      });
    }
  };
  closemodal = () => {
    this.setState({ openview: false, editopen: false, locationopen: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };
  // PDF FUNCTION
  // generatepdf = () => {
  //   if (this.state.tabledata.length === 0) {
  //     alert("Table data is empty");
  //   } else {
  //     // alert("ee")
  //     const doc = new jsPDF("a3")

  //     var bodydata = [];
  //     this.state.tabledata.map((data, index) => {
  //       bodydata.push([
  //         index + 1,
  //         data.customer,
  //         data.nursename,
  //         data.working_hours,
  //         data.booked_date,
  //         data.fromdate,
  //         data.todate,
  //         data.status,
  //         data.totalcost,
  //       ]);
  //     });
  //     doc.autoTable({
  //       beforePageContent: function (data) {
  //         doc.text("Total Bookings :", 13, 60); // 15,13 for css
  //       },
  //       margin: { top: 65 },
  //       showHead: "everyPage",
  //       theme: "grid",

  //       head: [
  //         [
  //           { content: "S.No", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Member", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Nurse", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Duty Hours", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Booked on", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "From", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "To", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Status", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //           { content: "Fee (KWD)", styles: { fillColor: [81, 15, 48], textColor: 255, fontStyle: "bold", halign: "center" } },
  //         ],
  //       ],
  //       body: bodydata,

  //     });

  //     doc.save("TotalBookings.pdf");
  //   }
  // };
  // PRINT FUNCTION

  generatepdf = () => {
    if (this.state.tabledata.length === 0) {
      alert("Table data is empty");
    } else {
      const doc = new jsPDF("a3");
      var bodydata = [];
      this.state.tabledata.map((data, index) => {
        bodydata.push([
          // index + 1,
          { content: index + 1, styles: { halign: "center" } },
          { content: data.customer, styles: { halign: "center" } }, // Left align for Member column
          { content: data.nursename, styles: { halign: "center" } },
          { content: data.working_hours, styles: { halign: "center" } }, // Left align for Member column
          { content: data.booked_date, styles: { halign: "center" } },

          { content: data.fromdate, styles: { halign: "center" } }, // Left align for Member column
          { content: data.todate, styles: { halign: "center" } },

          { content: data.status, styles: { halign: "center" } }, // Left align for Member column
          { content: data.totalcost, styles: { halign: "right" } },

          // data.working_hours,
          // data.booked_date,
          // data.fromdate,
          // data.todate,
          // data.status,
          // data.totalcost,
        ]);
      });

      const bodyStyles = {
        lineWidth: 0.3,
        halign: "center", // Align content to the center horizontally
      };

      doc.autoTable({
        beforePageContent: function (data) {
          doc.setTextColor(81, 15, 48);
          doc.text("Total Bookings :", 14, 43); // 15,13 for css
        },
        margin: { top: 50 },
        showHead: "everyPage",
        theme: "grid",

        head: [
          [
            {
              content: "S.No",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Member",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Nurse",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Duty Hours",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Booked on",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "From",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "To",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Status",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
            {
              content: "Fee (KWD)",
              styles: {
                fillColor: [81, 15, 48],
                textColor: 255,
                fontStyle: "bold",
                halign: "center",
              },
            },
          ],
        ],

        body: bodydata,
        bodyStyles: {
          cellStyles: bodydata.map((row) =>
            row.map((cell) => cell.styles || bodyStyles)
          ),
          lineWidth: 0.3,
        },
      });

      doc.save("TotalBookings.pdf");
    }
  };

  generateprint = () => {
    this.setState({
      printComOpen: true,
    });
  };
  // API FUNC
  componentDidMount = () => {
    this.GetApiFunction();

    this.intervalId = setInterval(() => {
      this.GetApiFunction();
    }, 120000);
  };

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  GetApiFunction = () => {
    var tabledata = [];
    var tabledata1 = [];
    Axios({
      method: "post",
      url: apiurl + "Nurse/getnursehired",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "5",
        fromDate: this.state.from_date,
        toDate: this.state.to_date,
        period: "Day",
        nurseId: this.state.selected_nurseId,
        working_hrs: this.state.selected_working_hrs,
      },
    }).then((response) => {
      response.data.data.map((val, index) => {
        tabledata.push({
          customer: val.PatientName,
          appointmenttype: val.Appointment_type == 1 ?
          <div>
            <img width={35} src={logos} alt="" />
            <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
          </div> :
          val.Appointment_type == 2 ?
            "Walk-in" :
            val.Appointment_type == 3 ?
              <div>
                <img width={35} src={logos} alt="" />
                <span style={{ marginLeft: '5px' }}>App</span>
              </div> : "",
        paymenttype: val.payment_type == 1 ? "Direct Academy" :
          val.payment_type == 2 ?
            <div>
              <img width={35} src={logos} alt="" />
              <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
              <span style={{ marginLeft: '5px' }}>Link</span>
            </div> :
            val.payment_type == 3 ?
              <div>
                <img width={35} src={logos} alt="" />
                <span style={{ marginLeft: '5px' }}>App</span>
              </div> : "",
              status:val.paymentStatus == "Pending" ? <span style={{ color: '#FF0068'}}>{val.paymentStatus}</span> : val.paymentStatus ,
          nursename: val.paymentStatus == "Pending" ? "-" : val.Nursename || '',
          working_hours: val.paymentStatus == "Pending" ? "-" : val.working_hours || '',
          fromdate: val.paymentStatus == "Pending" ? "-" : moment(val.from_date).format("DD-MMM-YY") || '',
          todate: val.paymentStatus == "Pending" ? "-" : moment(val.to_date).format("DD-MMM-YY") || '',
          totalcost: val.paymentStatus == "Pending" ? "-" : formatMoney(val.total_charge) || '',

          id: index,
        });
        tabledata1.push({
          customer: val.PatientName,
          appointmenttype: val.Appointment_type == 1 ?
            "Member Walk-in" :
            val.Appointment_type == 2 ?
              "Walk-in" :
              val.Appointment_type == 3 ?
                "Member" : "",
          paymenttype: val.payment_type == 1 ? "Direct Academy" :
            val.payment_type == 2 ?
              "tom Link" :
              val.payment_type == 3 ?
                "Member app" : "",
          nursename: val.paymentStatus == "Pending" ? "-" : val.Nursename || '',
          working_hours: val.paymentStatus == "Pending" ? "-" : val.working_hours || '',
          fromdate: val.paymentStatus == "Pending" ? "-" : moment(val.from_date).format("DD-MMM-YY") || '',
          todate: val.paymentStatus == "Pending" ? "-" : moment(val.to_date).format("DD-MMM-YY") || '',
          totalcost: val.paymentStatus == "Pending" ? "-" : formatMoney(val.total_charge) || '',
          id: index,
        });
      });
      this.setState({
        tabledata: tabledata,
        tabledata1: tabledata1,
        totalValue: response.data.data,
        props_loading: false,
        // spinner:false
      });
    });
  };

  // DATE RANGE PICKER FUNCTION
  dayReport = (data) => {
    function formatTimeShow(h_24) {
      var h = Number(h_24.substring(0, 2)) % 12;
      if (h === 0) h = 12;
      return (
        (h < 10 ? "0" : "") +
        h +
        ":" +
        h_24.substring(3, 5) +
        (Number(h_24.substring(0, 2)) < 12 ? " AM" : " PM")
      );
    }

    var startdate = dateformat(data[0].startDate, "yyyy-mm-dd");
    var enddate = dateformat(data[0].endDate, "yyyy-mm-dd");
    this.setState({ spinner: true, from_date: startdate, to_date: enddate });
    var self = this;
    Axios({
      method: "post",
      url: apiurl + "Nurse/getnursehired",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "5",
        fromDate: startdate,
        toDate: enddate,
        period: "Day",
        nurseId: this.state.selected_nurseId,
        working_hrs: this.state.selected_working_hrs,
      },
    }).then((response) => {
      var tabledata = [];
      var tableDatafull = [];
      response.data.data.map((val, index) => {
        tabledata.push({
          customer: val.PatientName,
          appointmenttype: val.Appointment_type == 1 ?
          <div>
            <img width={35} src={logos} alt="" />
            <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
          </div> :
          val.Appointment_type == 2 ?
            "Walk-in" :
            val.Appointment_type == 3 ?
              <div>
                <img width={35} src={logos} alt="" />
                <span style={{ marginLeft: '5px' }}>App</span>
              </div> : "",
        paymenttype: val.payment_type == 1 ? "Direct Academy" :
          val.payment_type == 2 ?
            <div>
              <img width={35} src={logos} alt="" />
              <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
              <span style={{ marginLeft: '5px' }}>Link</span>
            </div> :
            val.payment_type == 3 ?
              <div>
                <img width={35} src={logos} alt="" />
                <span style={{ marginLeft: '5px' }}>App</span>
              </div> : "",
              status:val.paymentStatus == "Pending" ? <span style={{ color: '#FF0068'}}>{val.paymentStatus}</span> : val.paymentStatus ,
          nursename: val.paymentStatus == "Pending" ? "-" : val.Nursename || '',
          working_hours: val.paymentStatus == "Pending" ? "-" : val.working_hours || '',
          fromdate: val.paymentStatus == "Pending" ? "-" : moment(val.from_date).format("DD-MMM-YY") || '',
          todate: val.paymentStatus == "Pending" ? "-" : moment(val.to_date).format("DD-MMM-YY") || '',
          totalcost: val.paymentStatus == "Pending" ? "-" : formatMoney(val.total_charge) || '',

          id: index,

          // customer: val.PatientName,
          // nursename: val.Nursename,
          // working_hours: val.working_hours,
          // booked_date: moment(val.Bookeddate).format("DD-MMM-YY"),
          // fromdate: moment(val.from_date).format("DD-MMM-YY"),
          // todate: moment(val.to_date).format("DD-MMM-YY"),
          // status:
          //   (val.CompletedDays ? val.CompletedDays : 0) +
          //   "/" +
          //   (val.NoOfDays ? val.NoOfDays : 0),
          // totalcost: formatMoney(val.total_charge),
          // id: index,
        });
        tableDatafull.push(val);
      });
      this.setState({
        tabledata: tabledata,
        wk_Mn_Yr_Full_Data: tableDatafull,
        props_loading: false,
        spinner: false,
        totalValue: tableDatafull,
      });
    });
  };

  openAdvanceFilter = () => {
    this.setState({ open_filter: true });
  };
  closeAdvanceFilter = () => {
    this.setState({ open_filter: false });
  };

  submitAdvanceFilter = (selected_nurseId, selected_working_hrs) => {
    this.setState(
      { selected_nurseId, selected_working_hrs, open_filter: false },
      () => {
        this.GetApiFunction();
      }
    );
  };
  render() {
    const { Option } = Select;
    const { t } = this.props;

    const heading =
      localStorage.nurse_language == "ar"
        ? [
          { id: "", label: t("tbl_head_remarks") },
          { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
          // { id: "status", label: t("tbl_head_status") },
          { id: "todate", label: t("tbl_head_to") },
          { id: "fromdate", label: t("tbl_head_from") },
          // { id: "booked_date", label: t("tbl_head_booked_on") },
          { id: "working_hours", label: t("tbl_head_duty_hours") },
          { id: "nursename", label: t("tbl_head_nurse") },
          { id: "paymentstatus", label: t("paymentStatus") },
          { id: "paymenttype", label: t("paymentType") },
          { id: "appointmenttype", label: t("appointmentType") },
          { id: "customer", label: t("tbl_head_member") },
          { id: "", label: t("tbl_head_s_no") },
        ]
        : [
          { id: "", label: t("tbl_head_s_no") },
          { id: "customer", label: t("tbl_head_member") },
          { id: "appointmenttype", label: t("appointmentType") },
          { id: "paymenttype", label: t("paymentType") },
          { id: "paymentstatus", label: t("paymentStatus") },
          { id: "nursename", label: t("tbl_head_nurse") },
          { id: "working_hours", label: t("tbl_head_duty_hours") },
          // { id: "booked_date", label: t("tbl_head_booked_on") },
          { id: "fromdate", label: t("tbl_head_from") },
          { id: "todate", label: t("tbl_head_to") },
          // { id: "status", label: t("tbl_head_status") },
          { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
          { id: "", label: t("tbl_head_remarks") },
        ];
    //SERACH FUNCTION
    const { Search } = Input;
    const Tabledatas = this.state.tabledata.filter((data) => {
      if (this.state.search === null) return data;
      else if (
        (data.nursename !== null &&
          data.nursename
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.customer != null &&
          data.customer
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.working_hours != null &&
          data.working_hours
            .toString()
            .includes(this.state.search.toString())) ||
        (data.booked_date != null &&
          data.booked_date.toString().includes(this.state.search.toString())) ||
        (data.fromdate != null &&
          data.fromdate
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.todate != null &&
          data.todate
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.totalcost != null &&
          data.totalcost.toString().includes(this.state.search.toString()))
      ) {
        return data;
      }
    });

    //EXCEL FUNCTION

    var multiDataSetbody = [];
    this.state.tabledata.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.customer },
          { value: xldata.nursename },
          { value: xldata.working_hours },
          { value: xldata.booked_date },
          { value: xldata.fromdate },
          { value: xldata.todate },
          { value: xldata.status },
          { value: xldata.totalcost },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },

          {
            value: xldata.customer,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.nursename,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.working_hours,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.booked_date,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.fromdate,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.todate,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.status,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.totalcost,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
        ]);
      }
    });

    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } }, // Change color to #510f30
              font: { color: { rgb: "FFFFFF" } }, // Change text color to white
            },
          },
          {
            title: "Member",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "Nurse",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "Duty Hours",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "Booked on",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "From",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "To",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "Status",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
          {
            title: "Fee (KWD)",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } },
            },
          },
        ],
        data: multiDataSetbody,
      },
    ];
    return (
      <>
        <div className="title_dashboard">
          <div className="title_header">{t("total_bookings_head")}</div>
          <div
            style={{ fontSize: "16px", display: "flex", alignItems: "center" }}
            className="range_picker_reduce"
          >
            <div
              className={`${this.props.i18n.language == "ar" ? "order_3" : ""
                } `}
              style={{ direction: this.props.i18n.language == "ar" ? "ltr" : "" }}
            >
              <DateRangeSelect
                dynalign={"dynalign"}
                rangeDate={(item) => this.dayReport(item)}
                t={t}
                i18n={this.props.i18n}
              />
            </div>
            <img
              src={Filter}
              className={`${this.props.i18n.language == "ar" ? "order_2" : ""
                } `}
              style={{ height: "22px", marginRight: '20px' }}
              onClick={this.openAdvanceFilter}
            />
            <Search
              placeholder={t('search')}
              onSearch={(value) => console.log(value)}
              style={{
                width: 150, direction: this.props.i18n.language == "ar" ? "rtl" : "", margin: "0px 10px 0px 0px"
              }}
              className={`${this.props.i18n.language == "ar" ? "order_1" : ""
                }`}
              onChange={(e) => this.searchChange(e)}
            />
            <div
              className={`${this.props.i18n.language == "ar" ? "order_0" : ""
                } icon_head`}
              style={{
                direction: this.props.i18n.language == "ar" ? "rtl" : "",

              }}
            >
              {/* <ReactSVG
                src={pdf}
                onClick={this.generatepdf}
                style={{marginRight:this.props.i18n.language == "ar" ? "15px" : "15px", marginLeft: this.props.i18n.language == "ar" ?"15px" : "15px" }}
              /> */}
              <PDF
                weekMonthYearData={this.state.tabledata1}
                headRows={headRows}
                filename={"Total Bookings"}
                headingTitle={'Total Bookings'}
                lang={this.props.i18n.language}
              />

              <img
                onClick={() => {
                  downloadExcel('Total Bookings', this.state.tabledata1, headRows, '', [])
                }}
                src={excel}
                style={{ cursor: 'pointer' }}
              />
             {this.state.tabledata1.length === 0 ? (
                <ReactSVG src={print} onClick={this.Notification} />
              ) : (
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
              )}
              <div style={{ display: "none" }}>
                <PrintData
                  printTableData={this.state.tabledata1}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="nurse_book_table_div">
          <Tablecomponent
            heading={heading}
            // tableicon_align={"cell_eye"}
            rowdata={Tabledatas.length === 0 ? [] : Tabledatas}
            // rowdata={this.tabledata && this.tabledata}
            // rowdata={[]}
            // rowdata={this.state.tabledata}
            props_loading={this.state.props_loading}
            DeleteIcon="close"
            EditIcon="close"
            HistoryIcon="close"
            GrandTotal="close"
            modelopen={(e, id) => this.modelopen(e, id)}
            LocationOnIcon="close"
            t={t}
            i18n={this.props.i18n}
          />
          <NurseView
            open={this.state.openview}
            ViewData={this.state.OpenViewData}
            onClose={this.closemodal}
            is_from_manage_nuser="true"
            t={t}
            i18n={this.props.i18n}
          />
          <Modalcomp
            visible={this.state.locationopen}
            title={"Nurse Tracking"}
            closemodal={(e) => this.closemodal(e)}
            modelwidthClass="nurse_location_modal_div"
            t={t}
            i18n={this.props.i18n}
          // xswidth={"xs"}
          >
            <NurseLocationModal
              locationdata={this.state.locationdata}
              t={t}
              i18n={this.props.i18n}
            />
          </Modalcomp>
        </div>
        <AdvanceFilter
          open={this.state.open_filter}
          onClose={this.closeAdvanceFilter}
          submitAdvanceFilter={this.submitAdvanceFilter}
          t={t}
          i18n={this.props.i18n}
        />
      </>
    );
  }
}
export default NursebookedTable;