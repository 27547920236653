import React, { Component  } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import TotalnurseTable from "./TotalnurseTable";
import Paper from "@material-ui/core/Paper";
import Plus from "../../Images/plus.png";
import Nurse_form from "./Nurse_form";
import { Input } from "antd";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import DeleteNurse from '../../helpers/ModalComp/deleteModal';
import { apiurl } from "../../App";
import Axios from "axios";
import moment from "moment";
import ShowNotification from "../ShowNotification/ShowNotification";
import RightArrowOnly from "../../Images/right-arrow-only.png";
import ArrowLeft from "@material-ui/icons/ChevronLeftSharp";
import ActiveNurseStatus from "../../helpers/ModalComp/Activenurse";
class TotalnurseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tableData: [],
      search: null,
      props_loading: true,
      nationality: [],
      visible: false,
      successmsg: "",
      issuccess: false,
      nurseoflength: [],
      deleteopen : false,
      selectedData : [],
    };
  }

  componentWillMount() {
    this.getNationality();

    this.intervalId = setInterval(() => {
      this.getTableData();
    }, 120000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  getNationality = () => {
    Axios({
      method: "GET",
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: apiurl + "nurse/getNationality",
    }).then((response) => {
      this.setState(
        {
          nationality: response.data.data,
        },
        () => {
          this.getTableData();
        }
      );
    });
  };

  // get table data
  getTableData = () => {
    this.state.nurseoflengt = [];
    var self = this;
    const { t } = this.props;
    Axios({
      method: "POST",
      url: apiurl + "nurse/getNurseDetails",
      data: {
        vendorId: localStorage.getItem("nurse_vendor_id") || "12",
      },
    })
      .then((response) => {
        var tableData = [];
        console.log("nurselog", response.data.data.length);
        this.state.nurseoflength = response.data.data;
        response.data.data.map((val) => {
          tableData.push({
            nurseName: val.name,
            gender: val.gender,
            age: val.age, //moment().diff(val.dob, "years"),
            experience: val.experience,
            nationality: val.nationality_name || "",
            // active_status: <div><input type="checkbox" checked={val.is_active} onChange={(e)=>this.handleActive(e,val)}/>Active</div>,
            
            active_status: 
              // <div>
              //   <input 
              //     type="checkbox" 
              //     checked={val.is_active === 1} 
              //     onChange={(e) => this.handleActive(e, val)}
              //   />
              //   <span style={{ color: val.is_active === 1 ? "#510F30" : "#FF2E83" }}>
              //     {val.is_active === 1 ? "Active" : "In active"}
              //   </span>

              // </div>,

            
            // <div style={{ display: "flex", alignItems: "center",justifyContent:'center', gap: "10px" }}>
            <div className="row m-0" style={{textAlign:'center',justifyContent:'end',alignItems:'center'}}>
              <div className="col-2">
                <input
                  type="checkbox"
                  checked={val.is_active === 1}
                  onChange={(e) => this.handleActive(e, val)}
                  style={{
                    appearance: "none",
                    width: "24px",
                    height: "24px",
                    border: `2px solid ${val.is_active === 1 ? "#F49C7A" : "#F49C7A"}`, 
                    borderRadius: "4px",
                    backgroundColor: "white",
                    cursor: "pointer",
                    position: "relative",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
              <div className="col-6" style={{padding:'0px 10px',textAlign:'justify',textTransform:'none'}}>
                <span
                  style={{
                    color: val.is_active === 1 ? "#510F30" : "#FF0068",
                    fontSize: "12.5px",
                    fontWeight: "500",
                  }}
                >
                  {/* {val.is_active === 1 ? "Active" : "In active"} */}
                  <span>
              {val.is_active === 1 ? t('tbl_head_Active') : t('tbl_head_Inactive')}
            </span>
              </span>
              </div>
              
              <style>
                {`
                  input[type="checkbox"]:checked::after {
                    content: '✔';
                    font-size: 19px;
                    font-weight: bold;
                    color: #F49C7A; 
                    position: absolute;
                  }
                  input[type="checkbox"]:not(:checked)::after {
                    content: '❌';
                    font-size: 18px;
                    font-weight: bold;
                    color: #FF2E83; 
                    position: absolute;
                  }
                    input[type="checkbox"]:focus {
                    outline: none;
                    background-color: white !important; 
                  }
                `}
              </style>
            </div>,
            id: val.nurseId,
          });
        });
        self.setState({
          tableData: tableData,
          props_loading: false,
          totalData: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
   handleActive = (e,data) => {
    this.setState({ deleteopen: true, selectedData: data });
    console.log(11111111,e,2222,data)
  }



  openmodal = () => {
    this.setState({ open: true });
  };
  onclosemodal = () => {
    this.setState({ open: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };


  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };
  closemodal = () => {
    this.setState({ deleteopen: false });
};

  // activenurse = (data) => {
  //   debugger
  //   let self = this;
  //   console.log("data",data)
  //   data = {
  //     "nurseId": data.nurseId,
  //     "is_active": data.is_active == 1 ? 0 : 1,
  //    }
  //   Axios({
  //       method: "POST",
  //       url: apiurl + "nurse/editNurseActive",
  //       data: data,
  //     })
  //       .then((response) => {
  //         if (response.data.status == '1') {
  //           if (localStorage.nurse_language == 'ar') {
  //             this.handleopenSuccess("!تم التحديث بنجاح", true);
              
  //           } else {
  //             this.handleopenSuccess("Nurse Activated Status successfully!", true);
              
  //           }
  //           this.getTableData();
  //           this.closemodal();
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };


  activenurse = (data) => {
    debugger;
    let self = this;
    console.log("data", data);

    // Toggle is_active value
    let updatedStatus = data.is_active == 1 ? 0 : 1;

    let requestData = {
        "nurseId": data.nurseId,
        "is_active": updatedStatus,
    };

    Axios({
        method: "POST",
        url: apiurl + "nurse/editNurseActive",
        data: requestData,
    })
    .then((response) => {
        if (response.data.status == '1') {
            let successMessage = "";

            if (localStorage.nurse_language == 'ar') {
                successMessage = updatedStatus === 1 ? "تم تفعّيل الممرضة بنجاح.": "تم إيقاف تفعّيل الممرضة بنجاح";
            } else {
                successMessage = updatedStatus === 1 
                    ? "Nurse Activated successfully!" 
                    : "Nurse Deactivated successfully!";
            }

            this.handleopenSuccess(successMessage, true);
            this.getTableData();
            this.closemodal();
        }
    })
    .catch((error) => {
        console.log(error);
    });
};



  render() {
    // console.log("tableData", this.state.tableData);
    // console.log("nationality", this.state.nationality);
    const { t } = this.props;

    const { Option } = Select;
    const { Search } = Input;
    const searchdata = [];
    this.state.tableData.filter((data, index) => {
      if (this.state.search === undefined || this.state.search === null) {
        searchdata.push({
          nurseName: data.nurseName,
          gender: data.gender,
          age: data.age,
          experience: data.experience,
          nationality: data.nationality,
          is_activeStatus: data.active_status,
          id: data.id,
        });
      } else if (
        (data.nurseName !== null &&
          data.nurseName
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.gender !== null &&
          data.gender
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.age !== null &&
          data.age.toString().includes(this.state.search.toString())) ||
        (data.experience !== null &&
          data.experience.toString().includes(this.state.search.toString())) ||
        (data.nationality !== null &&
          data.nationality
            .toLowerCase()
            .includes(this.state.search.toLowerCase()))
      ) {
        searchdata.push({
          nurseName: data.nurseName,
          gender: data.gender,
          age: data.age,
          experience: data.experience,
          nationality: data.nationality,
          is_activeStatus:data.active_status,
          id: data.id,
        });
      }
    });

    return (
      <div>
        <div className="title_dashboard">
          {localStorage.nurse_language == "ar" ? (
            <div
              className="title_header"
              style={{
                direction: localStorage.nurse_language == "ar" ? "rtl" : "",
              }}
            >
              {this.props.isfromverification && (
                <img
                  src={RightArrowOnly}
                  style={{
                    marginLeft:
                      localStorage.nurse_language == "ar" ? "10px" : "",
                    cursor: "pointer",
                  }}
                  width={18}
                  height={18}
                  alt="arrow"
                  onClick={() => {
                    this.props.backVerification();
                  }}
                />
              )}
              {t("manage_nurses_head")}
            </div>
          ) : (
            <div className="title_header">
              {this.props.isfromverification && (
                <ArrowLeft
                  className="left_arrow_dashboard"
                  onClick={() => {
                    this.props.backVerification();
                  }}
                >
                  {" "}
                </ArrowLeft>
              )}
              {t("manage_nurses_head")}
            </div>
          )}
          <div
            style={{
              direction: this.props.i18n.language == "ar" ? "rtl" : "",
              display: this.props.i18n.language == "ar" ? "flex" : "",
              alignItems: this.props.i18n.language == "ar" ? "center" : "",
            }}
          >
            <Search
              className="mr-5"
              placeholder={t("search")}
              onSearch={(value) => console.log(value)}
              style={{
                width: 150,
                textAlign: this.props.i18n.language == "ar" ? "right" : "",
                direction: this.props.i18n.language == "ar" ? "rtl" : "",
              }}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <img
              src={Plus}
              onClick={this.openmodal}
              style={{
                cursor: "pointer",
                height: "35px",
                width: "35px",
                marginRight: this.props.i18n.language == "ar" ? "20px" : "",
              }}
            />
          </div>
        </div>
        {this.state.nurseoflength.length < 10 &&
          this.state.nurseoflength.length > 0 && (
            <div
              style={{
                fontSize: "17px !important",
                textAlign: "end",
                marginRight: "51px",
                color: "#ff0068",
                marginTop: "5px",
                fontSize: "16px",
                fontFamily: "titillium web !important",
              }}
            >
              {t("addMinimum10Nurses")}
            </div>
          )}

        <TotalnurseTable
          tableData={searchdata}
          getTableData={() => this.getTableData()}
          props_loading={this.state.props_loading}
          totalData={this.state.totalData}
          getNurseHistory={(id, nurseName) => {
            this.props.getNurseHistory(id, nurseName);
          }}
          nationality={this.state.nationality}
          is_activeStatus={this.state.active_status}
          t={t}
          i18n={this.props.i18n}
        />
        <Modalcomp
          visible={this.state.open}
          closemodal={this.onclosemodal}
          title={t("add_nurse")}
          clrchange="textclr"
          xswidth={"md"}
          t={t}
          i18n={this.props.i18n}
          modelwidthClass={`${
            this.props.i18n.language == "ar" ? "arabic_cls" : ""
          } `}
        >
          <Nurse_form
            visible={this.state.open}
            closemodal={this.onclosemodal}
            editopenModal={false}
            getTableData={() => this.getTableData()}
            nationality={this.state.nationality}
            handleopenSuccess={this.handleopenSuccess}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
          {this.state.deleteopen && (
            <Modalcomp
              visible={this.state.deleteopen}
              title={t("active_manage_nurse")} 
              closemodal={this.closemodal}
              xswidth={"xs"}
              clrchange="textclr"
              t={t}
              modelwidthClass={`${this.props.i18n.language === "ar" ? "arabic_cls" : ""} manage_nurse_delete_popup`}
              i18n={this.props.i18n}
            >
              {/* <ActiveNurseStatus
                activenurse={this.activenurse}  
                closemodal={this.closemodal}
                msg={t("active_nurse")}
                active_status_nusre = {this.state.selectedData}
                t={t}
                i18n={this.props.i18n}
                /> */}


              <ActiveNurseStatus
                  activenurse={this.activenurse}  
                  closemodal={this.closemodal}
                  // msg={t(this.props.is_active === 1 ? "active_nurse" : "in_active_nurse")}
                  msg={t(this.state.selectedData?.is_active === 1 ? "in_active_nurse" : "active_nurse")}
                  active_status_nusre={this.state.selectedData}
                  t={t}
                  i18n={this.props.i18n}
              />


              </Modalcomp>
          )}
  
      </div>
    );
  }
}
export default TotalnurseDashboard;
